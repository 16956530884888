import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ProductResultComponent } from "./product-result/product-result.component";
import { SearchComponent } from "./search/search.component";
import { BasicPipesModule } from "../pipes/pipes.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SnackbarComponent } from "./snackbar/snackbar.component";
import { SwitchComponent } from "./switch/switch.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";


@NgModule({
  declarations: [SearchComponent, ProductResultComponent, SnackbarComponent, SwitchComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    BasicPipesModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSlideToggleModule
  ],
  exports: [
    SearchComponent,
    ProductResultComponent,
    MatProgressBarModule,
    MatRippleModule,
    BasicPipesModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTooltipModule,
    SnackbarComponent,
    SwitchComponent
  ],
})
export class ComponentsModule {}
