import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable, map, take } from "rxjs";
import {
  GetCoins,
  UpdateDefaultCoin,
} from "src/app/states/actions/user.actions";
import { UserState } from "src/app/states/user.state";

@Component({
  selector: "app-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
})
export class SwitchComponent implements OnInit, AfterViewInit {
  @Select(UserState.coins) allCoins$: Observable<any>;
  @Select(UserState.defaultCoin) defaultCashierCoin$: Observable<any>;

  constructor(private store: Store) {}

  ngAfterViewInit(): void {
    this.store.dispatch(new GetCoins());
    this.allCoins$
      .pipe(
        map((coins) =>
          coins?.filter(
            (coin) =>
              coin.Name_Coin === "Colón" ||
              coin.Name_Coin === "Dolar Estadounidense"
          )
        )
      )
      .subscribe();
  }

  ngOnInit() {}

  toggleCoin() {
    this.defaultCashierCoin$.pipe(take(1)).subscribe((currentCoin) => {
      const newCoinId = currentCoin?.id === "2" ? "1" : "2";
      this.store.dispatch(new UpdateDefaultCoin(newCoinId));
    });
  }

  get selectedSymbol$(): Observable<string> {
    return this.defaultCashierCoin$.pipe(
      map((coin) => {
        return coin?.Symbol_Coin ?? "";
      })
    );
  }
}
